//
// Overriding path variable
// --------------------------------------------------

@import "flag-icon/less/variables";

@flag-icon-css-path: '../flag/flag-icon/flags';

//
// Flag
// --------------------------------------------------

[class^="flag-"],
[class*=" flag-"] {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: unit((4 / 3), em);
    line-height: 1em;
    &:before {
        content: "\00a0";
    }
    &.oc-flag-squared {
        width: 1em;
    }
}

.flag-icon(@country) {
    .flag-@{country} {
        background-image: ~"url(@{flag-icon-css-path}@{flag-icon-rect-path}/@{country}.svg)";
        &.oc-flag-squared {
            background-image: ~"url(@{flag-icon-css-path}@{flag-icon-square-path}/@{country}.svg)";
        }
    }
}

//
// Generate all flags
// --------------------------------------------------

@import "flag-icon/less/flag-icon-list";
@import "flag-icon/less/flag-icon-more";